import { atendimentoEncerrado, idAtendimento, usuarioEmAtendimento } from '../signals/atendimentos';
import { SttCircularProgress, SttTranslateHook } from '@stt-componentes/core';
import { useSignalEffect, useSignals } from '@preact/signals-react/runtime';
import React, { Suspense, lazy, memo, useContext, useEffect } from 'react';
import { SttHistoricoAtendimento } from '@stt-componentes/atendimento';
import { batch, signal } from '@preact/signals-react';
import { temPermissaoRBAC } from '../security/rbac';
import { CONSTANTES } from '../common/Constants';
import { makeStyles } from '@material-ui/core';
import usuario from '../signals/usuario';
import { getHeaders } from '../request';
import moment from 'moment';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
	tab: {
		height: '600px',
		overflow: 'scroll',
	},
}));

const ChatHistoricoSuspense = memo((props) => {
	const HistoricoChatContainer = lazy(() => import('./historicoChatContainer'));

	return (
		<Suspense fallback={<SttCircularProgress color='primary' />}>
			<HistoricoChatContainer {...props} />
		</Suspense>
	);
});

const AnexosHistoricoSuspense = memo((props) => {
	const HistoricoAnexoContainer = lazy(() =>import('./historicoAnexoContainer'));

	return (
		<Suspense fallback={<SttCircularProgress color='primary' />}>
			<HistoricoAnexoContainer {...props} />
		</Suspense>
	);
});

const historico = signal([]);
const carregouHistorico = signal(false);
const abas = signal([]);
const abaAtiva = signal('0');
const semHistoricoAtendimento = signal(false);

const resetHistorico = () => {
	batch(() => {
		historico.value = [];
		carregouHistorico.value = false;
		abas.value = [];
		abaAtiva.value = '0';
		semHistoricoAtendimento.value = false;
	});
};

const HistoricoContainer = ({ historicoAberto }) => {
	useSignals();
	const { strings } = useContext(SttTranslateHook.I18nContext);
	const classes = useStyles();

	useEffect(() => {
		if (!carregouHistorico.value) {
			baixarHistorico();
		}
	}, []);

	useSignalEffect(() => {
		if (!idAtendimento.value && !temPermissaoRBAC(usuario, CONSTANTES.PERMISSOES.ATENDIMENTO) && !carregouHistorico.value) {
			baixarHistorico();
		}
	});

	useSignalEffect(() => {
		if (!usuarioEmAtendimento.value) {
			resetHistorico();
		}
	});

	const baixarHistorico = () => {
		axios.get(`${global.gConfig.url_base_backend}/atendimento/historico`, { params: { funcionarioAtendido: usuarioEmAtendimento.value.idFuncionario, }, headers: getHeaders() })
			.then((response) => {
				const { data } = response;

				let itensHistorico = data.data.itens;
				if (itensHistorico.length === 0) {
					semHistoricoAtendimento.value = true;
					return;
				}

				if (idAtendimento.value && !atendimentoEncerrado.value) {
					itensHistorico = itensHistorico.filter(ih => ih._id !== idAtendimento.value)
				}

				itensHistorico.forEach((i) => {
					const dataInicioAtendimento = moment(i.dataHoraInicio).format('HH:mm - DD/MM/YYYY');

					if (i.mensagens.length === 0) {
						i.mensagens.push({
							notificacao: true,
							mensagem: strings.nenhumaMensagemEnviadaAtendimento,
						});
					}
					i.mensagens.unshift({
						notificacao: true,
						mensagem: `Atendimento iniciado às ${dataInicioAtendimento}`,
						atendimento: true,
					});
					if (i.dataHoraFim) {
						const dataFimAtendimento = moment(i.dataHoraFim).format('HH:mm - DD/MM/YYYY');
						i.mensagens.push({
							notificacao: true,
							mensagem: `Atendimento finalizado às ${dataFimAtendimento}`,
							atendimento: true,
						});
					}
				});

				carregouHistorico.value = true;
				historico.value = itensHistorico;
				abas.value = [
					{
						titulo: strings.chat,
						conteudo: ChatHistoricoSuspense,
						key: 'ChatHistoricoSuspense',
						permanente: true,
						historico: historico.value,
						className: classes.tab,
					},
					{
						titulo: strings.anexos,
						conteudo: AnexosHistoricoSuspense,
						key: 'AnexosHistoricoSuspense',
						permanente: true,
						historico: historico.value,
					},
				];
			})
			.catch((err) => console.log(err));
	};

	return (
		<SttHistoricoAtendimento
			strings={strings}
			historicoAberto={historicoAberto}
			abas={abas}
			abaAtiva={abaAtiva}
			semHistoricoAtendimento={semHistoricoAtendimento}
		/>
	);
};

export default HistoricoContainer;
import React, { useContext, useEffect } from "react";
import { SttTranslateHook } from '@stt-componentes/core';
import { useSignalEffect, useSignals } from "@preact/signals-react/runtime";
import { atendenteOnline, solicitouAtendimento } from "../signals/atendimentos";
import usuario from "../signals/usuario";
import socket from "../signals/socket";
import { EVENTOS_SOCKET } from "../common/Constants";
import { SttPainelEspera } from '@stt-componentes/atendimento';

const PainelEsperaContainer = ({ historicoAberto }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    useSignals();

    useSignalEffect(() => {
        if (socket.value) {
            socket.value.on(EVENTOS_SOCKET.ATENDENTE_ONLINE, (dados) => {
                const mensagemRecebida = JSON.parse(dados);
                atendenteOnline.value = mensagemRecebida.atendenteOnline;
            });
        }
    });

    useEffect(() => {
        return () => {
            if (socket.value) {
                socket.value.removeAllListeners(EVENTOS_SOCKET.ATENDENTE_ONLINE);
            }
        }
    }, []);

    return (
        <SttPainelEspera
            strings={strings}
            atendenteOnline={atendenteOnline}
            solicitouAtendimento={solicitouAtendimento}
            nome={usuario.value?.nome || ''}
            onClickSolicitarAtendimento={() => {
                socket.value.emit(EVENTOS_SOCKET.SOLICITAR_ATENDIMENTO);
                solicitouAtendimento.value = true;
            }}
            onClickAbrirHistorico={() => {
                historicoAberto.value = true;
            }}
        />
    );
}

export default PainelEsperaContainer;